
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			settings: 'settings/settings/settings'
		}),
		services(){
			return [
			{
					title: 'КРЕДИТ',
					content: `Выгодная ставка по кредиту от ${this.settings.credit_percent}.`,
					image: require('~/assets/img/services/services2.webp'),
					buttonFunc: () => this.$router.push(`/credit`)
				},
				{
					title: 'TRADE-IN',
					content: `Экономия ${this.settings.sale_tradein}₽ при обмене по программе Trade-In.`,
					image: require('~/assets/img/services/services1.webp'),
					buttonFunc: () => this.$router.push(`/exchange`)

				},
				{
					title: 'ВЫКУП',
					content: `Высокая оценка вашего автомобиля. Мгновенная выплата.`,
					image: require('~/assets/img/services/services3.webp'),
					buttonFunc: () => this.$router.push(`/buyout`)

				},
				// {
				// 	title: 'РАССРОЧКА',
				// 	content: `Покупка авто без переплат.`,
				// 	image: require('~/assets/img/services/services4.webp'),
				// 	buttonFunc: () => this.$router.push(`/installment`)

				// }
			]
		}
	},
	mounted() {
		setTimeout(() => {
			let leftPadding = this.$refs.asdd.getBoundingClientRect().left
			let width = this.$refs.asdd.getBoundingClientRect().width
			let aaa = leftPadding * 2 / (width / 2) + 2
			let aaa1 = leftPadding * 2 / (width / 1) + 1.5

			new swiper.default('.swiper--catalog.swiper.swiper--services', {
				modules: [swiper.Navigation, swiper.Autoplay],
				loop: false,
				// autoplayDisableOnInteraction: true,
				autoplay: false,
				initialSlide: 0,
				// centeredSlides: true,
				watchSlidesProgress: true,
				// centeredSlidesBounds:true,
				// roundLengths: true,

				// centerInsufficientSlides:true,
				// slidesOffsetAfter: this.$refs.asdd.getBoundingClientRect().left,
				// cssMode:true,
				// slidesOffsetBefore: leftPadding,
				// slidesOffsetAfter: leftPadding,
				breakpoints: {
					// when window width is >= 320px
					220: {
						slidesPerView: 1.25,
						spaceBetween: 20
					},
					600: {
						slidesPerView: 2,
						spaceBetween: 24,

					}

				},
				navigation: {
					nextEl: '.catalog__list .swiper-button-next--services',
					prevEl: '.catalog__list .swiper-button-prev--services',
				},
			});
		})

	},
}
