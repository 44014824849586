import seoTags from "@/apollo/queries/seoTags";
import folders from "@/apollo/queries/folder/folders";
import generations from "@/apollo/queries/generations";
import {currentDomain} from "~/app/variables";
import { mapGetters } from "vuex";

export default {
  async asyncData({ app, route, store, error }) {
    let client = app.apolloProvider.defaultClient

    let seo = await client.query({
      query: seoTags,
      variables: { site_id: store.getters.site_id, url: route.path === '/' ? '/home' : route.path }
    })

    if (route.params.mark) {
      let response = await client.query(
        {
          query: folders,
          variables: {
            site_id: store.getters.site_id,
            mark_slug: route.params.mark,
            category: route.params.category
          }
        })
      store.commit('folders/folders/SET_FOLDERS', response.data.folders)
    }
    // if(route.params.bank){
    //     try {
    //         let response = await client.query(
    //                 {
    //                     query: bank,
    //                     variables: {
    //                         site_id: store.getters.site_id,
    //                         slug: route.params.bank,
    //                     }
    //                 })
    //         store.commit('banks/SET_BANK', response.data.bank)
    //     }
    //     catch (er){
    //         error({statusCode: 404, message: '404'})
    //     }
    // }
    if (route.params.model) {
      let variables = {
        site_id: store.getters.site_id,
        mark_slug: route.params.mark,
        folder_slug: route.params.model,
      }
      // if(route.params.category === 'new') variables.category= 'new'
      let response = await client.query(
        {
          query: generations,
          variables: variables
        })
      store.commit('generations/generations/SET_GENERATIONS', response.data.generations)
    }

    // if (seo.data.seoTag.title === null && seo.data.seoTag.page_title === null) {
    //     error({ statusCode: 404, message: '404' })
    // }
    return {
      description: seo.data.seoTag.description,
      pageTitle: seo.data.seoTag.page_title,
      title: seo.data.seoTag.title,
      crumbs: seo.data.seoTag.crumbs
    }
  },
  computed: {
    ...mapGetters({
      domain: 'domain',
      offer: 'catalog/catalog-cars/offer',
    }),
  },
  head() {
    let currentMeta = [
      {
        hid: 'description',
        name: 'description',
        content: `${this.description}`
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: `https://${this.domain}${this.$route.path}`,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: `${this.title}`
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: `${this.description}`
      },

    ]

    if (!currentDomain(this.domain).isIndex) {
      currentMeta.push({
        name: 'robots',
        content: 'noindex, nofollow'
      })
    }

    if (this.offer) {
      currentMeta.push({
        hid: 'og:image',
        property: 'og:image',
        content: this.offer.images[0].thumb
      })
    } else {
      currentMeta.push({
        hid: 'og:image',
        property: 'og:image',
        content: `https://${this.domain}/preview.png`
      })
    }
    return {
      title: `${this.title}`,
      link: [
        {
          rel: 'canonical',
          href: `https://${this.domain}${this.$route.path}`
        }
      ],
      meta: currentMeta
    }
  }
}
